<template>
  <div class="hero mt-4 pt-4" :class="{ 'app-loaded': appLoaded }">
    <div class="container d-flex flex-column mt-4">
      <h1 class="page-title mt-0 mb-2 py-4 fs-5 text-center">User Manager</h1>
      <img
        src="../images/img-user-manager.svg"
        alt="healthscholars-banner"
        class="hero-image mx-auto mb-4 p-1"
      />
      <p class="text-center mt-4 mx-auto px-4">
        Welcome to Health Scholars User management tool.<br />If you would like assistance or
        require support, please contact us at
        <a href="mailto:support@healthscholars.com">support@healthscholars.com</a>
        or by phone at <a href="tel:7207418129">720 741 8129</a>
      </p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BannerComponent',
  computed: {
    appLoaded() {
      return this.$route.name == 'landing' ? true : false
    },
  },
})
</script>

<style>
.hero-image {
  background: var(--color-light);
  border-radius: 2rem;
}

.hero {
  container-type: inline-size;
  opacity: 0;
  transition: opacity 0.4s ease 1s;
}

.app-loaded {
  opacity: 1;
  transition: opacity 0.4s ease 1s;
}

@container (max-width: 900px) {
  .hero-image {
    max-width: 400px;
  }
}

@container (min-width: 901px) {
  .hero-image {
    max-width: 650px;
  }
}
</style>
