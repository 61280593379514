<template>
  <div class="select-hs d-flex flex-column mr-3 w-50" :class="required ? 'is-required' : ''">
    <label class="select-hs--label" :for="name" :disabled="disabled">{{ label }}</label>
    <select
      class="select-hs--select"
      v-model="selected"
      :data-test="dataTest"
      :required="required"
      :name="name"
      :disabled="disabled"
    >
      <option v-for="item in posibleItems" :key="item.id" :value="item.id">
        {{ item.value }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SelectComponent',
  data() {
    return {
      selected: '',
    }
  },
  props: {
    valueHandler: { type: String, default: '' },
    dataTest: { type: String, default: '' },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    label: { type: String, default: '' },
    name: { type: String, default: 'select' },
    posibleItems: {
      type: Array as () => Array<{ id: string; value: string }>,
      default: () => [],
    },
  },
  watch: {
    selected(value) {
      this.$emit('onChange', value)
    },
    valueHandler(value) {
      this.selected = value
    },
  },
  mounted() {
    this.selected = this.valueHandler || ''
  },
})
</script>

<style scoped>
select {
  padding-top: var(--size-2);
  padding-right: var(--size-4);
  padding-bottom: var(--size-2);
  padding-left: var(--size-2);
  overflow: visible;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  height: 3rem;
  background: #fff;
  border-radius: 5px;
  border: 2px solid #d3d3d3;
  outline: 0;
  color: #000;
  width: 100%;
  transition: 0.3s all ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
label {
  font-size: 0.9rem;
}
.is-required > label::after {
  position: relative;
  color: red;
  content: '*';
  margin-left: 5px;
  top: 2px;
}
</style>

<style lang="sass" scoped>
.select-hs
  position: relative
  width: 20rem
  display: flex
  margin-right: 0.75em
  margin-bottom: 2.2em
  &--label
    position: absolute
    transform: translateY(-50%)
    margin-bottom: 0px !important
    padding: 0 0.5rem
    color: gray
    cursor: text
    background: #fff !important
    transition: top 200ms ease-in, font-size 200ms ease-in, left 200ms ease-in
    top: 0.1rem
    font-size: 0.8rem
    left: 0.4rem
    z-index: 20
    &:disabled
      cursor: not-allowed
      box-shadow: none
      opacity: 0.8
      color: lightgray !important
  &--select
    &:disabled
      cursor: not-allowed
      box-shadow: none
      opacity: 0.8
      color: lightgray !important
</style>
