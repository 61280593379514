import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia, PiniaVuePlugin } from 'pinia'
import { Auth0Plugin } from './auth'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import VTooltip from 'v-tooltip'

Vue.config.productionTip = false
Vue.use(PiniaVuePlugin)
const pinia = createPinia()

const clientId = process.env.VUE_APP_AUTH0_CLIENT_ID
const domain = process.env.VUE_APP_AUTH0_DOMAIN
const environment = process.env.VUE_APP_ENVIRONMENT || 'local'

Sentry.init({
  Vue,
  dsn: 'https://32575137b7a647998b804ba6d9de0cd1@o4504878347517952.ingest.sentry.io/4504912780525568',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: [
        'localhost',
        'https://user-manager.healthscholars.com',
        'https://user-manager-dev.healthscholars.com',
        /^\//,
      ],
    }),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
  environment,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: process.env.VUE_APP_ENV === 'develop' ? 0.02 : 0.05,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: process.env.VUE_APP_ENV === 'develop' ? 0.2 : 0.5,
})

Vue.use(Auth0Plugin, {
  domain,
  client_id: clientId,
  connection: process.env.VUE_APP_AUTH0_CONNECTION,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onRedirectCallback: () => {},
})

Vue.use(VTooltip)

new Vue({
  pinia,
  router,
  render: (h) => h(App),
}).$mount('#app')
