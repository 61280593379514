import { defineStore } from 'pinia'
import { readinessReport } from '@/types'
import { getInstance } from '../auth'
import Api from '@/api'

const usePanelStore = defineStore('panelStore', {
  // Once that everything is built we need to specify the
  // correct types for each data here!!
  state: () => ({
    isEditPanelOpen: false,
    isCreatePanelOpen: false,
    userId: '',
    tableRef: null as any,
    reports: {} as { [key: string]: readinessReport[] },
  }),
  actions: {
    setTableRef(ref: any) {
      this.tableRef = ref
    },
    unSelectRow() {
      if (this.tableRef) this.tableRef.onUnselectRow()
    },
    setCreatePanel() {
      this.userId = ''
      this.isEditPanelOpen = false
      this.isCreatePanelOpen = true
      this.unSelectRow()
    },
    setEditablePanel(userId: string) {
      this.userId = userId
      this.isCreatePanelOpen = false
      this.isEditPanelOpen = true
    },
    setUserReports(mysqlId: string, reports: readinessReport[]) {
      this.reports[mysqlId] = reports
    },
    async requestUserReports(mysqlId: string) {
      const authService = getInstance()
      const accessToken = await authService.getTokenSilently()
      const reports = await Api.getReadinessReport(accessToken, mysqlId)
      this.setUserReports(mysqlId, reports)
      return reports
    },
  },
  getters: {
    getUserReports: (state) => (mysqlId: string) => {
      if (!state.reports[mysqlId]) return []
      const records = state.reports[mysqlId]
      //sort by date
      records.sort((a, b) => {
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      })
      return records
    },
  },
})

export default usePanelStore
