import { defineStore } from 'pinia'
import { AuthUser, Role, actorUser } from '@/types'
import { useUsersStore } from '../store'
import Api from '@/api'

const useAuthStore = defineStore('authStore', {
  state: () => ({
    user: {} as actorUser,
  }),
  actions: {
    async setUser(user: AuthUser) {
      this.user.userId = user.sub
      this.user.picture = user.picture
      await this.requestGraphUser()
    },
    async requestGraphUser() {
      const gotUser = await Api.getActorUser(this.user.userId)
      const accountCode = await Api.getAccountCode(gotUser.accountId)
      this.user = {
        ...this.user,
        ...gotUser,
        accountCode,
      }
      useUsersStore().requestUsers({
        role: gotUser.role,
        institutionId: gotUser.institutionId,
        id: gotUser.userId,
      })
      useUsersStore().requestAccounts()
    },
    async setActiveAccountCode(accountId: string) {
      const accountCode = await Api.getAccountCode(accountId)
      this.user = {
        ...this.user,
        accountCode,
      }
    },
  },
  getters: {
    getId(): string {
      if (this.user) {
        return this.user.userId
      }
      return ''
    },
    getRole(): Role {
      if (!this.user.role) {
        return Role.undefined
      }
      return this.user.role
    },
    getFacility(): string {
      if (!this.user.institutionId) {
        return ''
      }
      return this.user.institutionId
    },
    getAccountId(): string {
      if (!this.user.accountId) {
        return ''
      }
      return this.user.accountId
    },
    getAccountCode(): string {
      if (!this.user.accountCode) return ''
      return this.user.accountCode
    },
    isSuperAdmin(): boolean {
      return this.user.role === Role.SuperAdmin
    },
    isAdmin(): boolean {
      return this.user.role === Role.Admin
    },
    isLearner(): boolean {
      return this.user.role === Role.Learner
    },
  },
})

export default useAuthStore
