import { computed, defineComponent, reactive, ref, watch } from 'vue'
import { usePanelStore, useUsersStore, useAuthStore, useNotificationStore } from '../../store'
import SelectComponent from '../SelectComponent.vue'
import {
  HSSidebar,
  HSButton,
  HSField,
  HSForm,
  HSSelectInput,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from '@healthscholars/healthscholars-vue'
import { UserCreatePanel, identifiersPanels, UserForm } from '../../types'
import useForms from '../../composables/useForms'

export default defineComponent({
  components: {
    HSSidebar,
    HSButton,
    HSField,
    HSForm,
    HSSelectInput,
    SelectComponent,
  },
  setup() {
    const {
      currentUserRole,
      formatStoreFacilities,
      formatStoreDepartments,
      roles,
      accounts,
      getFacilities,
      getDepartments,
      accountsAvailableToAssign,
      assignedAccounts,
      prepareAccess,
    } = useForms()

    const panelStore = usePanelStore()
    const usersStore = useUsersStore()
    const authStore = useAuthStore()
    const notificationStore = useNotificationStore()

    const selectAccountKey = ref('k-account')
    const selectFacilityKey = ref('k-facilities')
    const selectDepartmentKey = ref('k-departments')
    const rolesKey = ref('k-roles')

    const form = ref<HTMLFormElement>()
    const facilities = ref()
    const departments = ref()
    const usersList = ref<UserForm[]>([])
    const activeIndex = ref(usersList.value.length)
    const confirmPassword = ref()

    const loadings = reactive({ save: false })
    const oUser = ref<UserCreatePanel>({
      access: [] as string[],
      sf_account: { account_id: '', name: '' },
      department: { id: '', name: '' },
      facility: { institution_id: '', name: '' },
      email: '',
      family_name: '',
      given_name: '',
      nickname: '',
      password: '',
      role: '',
    })
    const local = reactive<identifiersPanels>({
      account_id: '',
      institution_id: '',
      department_id: '',
      role: '',
    })

    const isEditing = computed(() => activeIndex.value < usersList.value.length)
    const isAssignAccounts = computed(() => local.role === 'Admin' && local.account_id !== '')

    const refreshFields = () => {
      selectAccountKey.value += 'a'
      selectFacilityKey.value += 'f'
      selectDepartmentKey.value += 'd'
      rolesKey.value += 'r'
    }

    const resetForm = () => {
      oUser.value.access = [] as string[]
      ;(oUser.value.sf_account = { account_id: '', name: '' }),
        (oUser.value.department = { id: '', name: '' }),
        (oUser.value.facility = { institution_id: '', name: '' })
      oUser.value.email = ''
      oUser.value.family_name = ''
      oUser.value.given_name = ''
      oUser.value.nickname = ''
      oUser.value.password = ''
      oUser.value.role = ''
      confirmPassword.value = ''
      local.account_id = ''
      local.institution_id = ''
      local.department_id = ''
      local.role = ''
      refreshFields()
      activeIndex.value = usersList.value.length
    }

    const isDisabled = (value: string) => oUser.value[value as keyof UserCreatePanel] === ''

    const handleOnchange = (field: string, value: string) => {
      local[field as keyof identifiersPanels] = value
    }

    const handleAccess = (value: string) => {
      if (!value) return
      oUser.value.access.push(value)
      prepareAccess(oUser.value.access, isAssignAccounts.value)
    }

    const deleteAccess = (index: number, accountId: string): void => {
      if (accountId === local.account_id) {
        notificationStore.addNotification(
          `You cannot delete the default account of the user`,
          'is-danger'
        )
        return
      }
      oUser.value.access = oUser.value.access.filter((id) => id !== accountId)
      assignedAccounts.value.splice(index, 1)
      prepareAccess(oUser.value.access, isAssignAccounts.value)
    }

    const validateForm = () => {
      if (form.value) {
        let htmlValidator = true
        htmlValidator = form.value.validate()
        return htmlValidator
      }
      return false
    }

    const addUser = () => {
      if (validateForm()) {
        if (oUser.value.password !== confirmPassword.value) {
          notificationStore.addNotification('Passwords do not match', 'is-danger')
          return
        }
        const validatePassword = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
        let text = ''
        if (!validatePassword.test(oUser.value.password)) {
          // check if password contains < or > characters
          if (oUser.value.password.includes('<') || oUser.value.password.includes('>')) {
            text =
              'Password must be minimum eight characters, at least one letter, one number and one special character. < or > characters are not considered special characters'
          } else {
            text =
              'Password must be minimum eight characters, at least one letter, one number and one special character'
          }
          notificationStore.addNotification(text, 'is-danger')
          return
        }
        if (oUser.value.nickname.trim().length == 0) {
          notificationStore.addNotification(
            'You must at least set one character for the username',
            'is-danger'
          )
          return
        }
        const user = {
          access: oUser.value.access,
          given_name: oUser.value.given_name,
          family_name: oUser.value.family_name,
          nickname: oUser.value.nickname,
          email: oUser.value.email,
          password: oUser.value.password,
          account_id: local.account_id,
          institution_id: local.institution_id,
          department_id: local.department_id,
          role: local.role,
        }
        usersList.value[activeIndex.value] = user as UserForm
        resetForm()
      } else {
        form.value!.validate()
      }
    }

    const editUserOnList = (index: number): void => {
      const user = { ...usersList.value[index] }
      oUser.value = {
        family_name: user.family_name,
        access: user.access,
        sf_account: { account_id: user.account_id, name: '' },
        department: { id: user.department_id, name: '' },
        facility: { institution_id: user.institution_id, name: '' },
        email: user.email,
        given_name: user.given_name,
        nickname: user.nickname,
        password: user.password as string,
        role: user.role,
      }
      confirmPassword.value = user.password as string
      activeIndex.value = index
      refreshFields()
      prepareAccess(oUser.value.access, isAssignAccounts.value)
    }

    const deleteUserOnList = (index: number): void => {
      usersList.value.splice(index, 1)
    }

    const saveUsersList = async (): Promise<void> => {
      loadings.save = true
      const payload = usersList.value.map((user) => ({
        actor: {
          role: authStore.getRole,
          accountId: authStore.getAccountId,
          id: authStore.getId,
        },
        action: {
          user: {
            institution_id: user.institution_id,
            email: user.email,
            password: user.password || 'Password1!',
            given_name: user.given_name,
            department_id: user.department_id,
            family_name: user.family_name,
            nickname: user.nickname,
            account_id: user.account_id,
            role: user.role,
            user_id: user.user_id,
            access: user.access.filter((access) => access !== ''),
          },
        },
      }))
      await usersStore.createUsersForm(payload)
      usersList.value = []
      loadings.save = false
      resetForm()
    }

    const resetUsersList = () => {
      usersList.value = []
      activeIndex.value = 0
    }

    watch(panelStore, () => {
      if (!panelStore.isCreatePanelOpen) {
        resetForm()
        return
      }
      refreshFields()
    })
    watch(
      () => local.account_id,
      async (account_id) => {
        if (account_id) {
          oUser.value.access = [account_id]
          local.institution_id = ''
          await getFacilities(account_id)
          facilities.value = formatStoreFacilities(local.account_id)
          selectFacilityKey.value += 'a'
        }
      }
    )
    watch(
      () => local.institution_id,
      async (institution_id) => {
        if (institution_id !== '') {
          if (!isEditing.value)
            (oUser.value.department = { id: '', name: '' }), await getDepartments(institution_id)
          departments.value = formatStoreDepartments(institution_id)
          selectDepartmentKey.value += 'd'
        }
      }
    )
    watch(
      () => oUser.value.given_name,
      (value) => {
        if (!isEditing.value && value.trim() !== '') {
          oUser.value.nickname = value.trim() + '.' + oUser.value.family_name.trim()
        }
      }
    )
    watch(
      () => oUser.value.family_name,
      (value) => {
        if (!isEditing.value && value.trim() !== '') {
          oUser.value.nickname = oUser.value.given_name.trim() + '.' + value.trim()
        }
      }
    )
    watch(
      () => isAssignAccounts.value,
      () => prepareAccess(oUser.value.access, isAssignAccounts.value)
    )

    return {
      // Propperties
      panelStore,
      oUser,
      selectAccountKey,
      selectFacilityKey,
      selectDepartmentKey,
      rolesKey,
      local,
      roles,
      facilities,
      departments,
      confirmPassword,
      usersList,
      loadings,
      accountsAvailableToAssign,
      assignedAccounts,
      // Computed
      accounts,
      currentUserRole,
      activeIndex,
      isEditing,
      isAssignAccounts,

      // Methods
      isDisabled,
      handleOnchange,
      resetForm,
      addUser,
      editUserOnList,
      deleteUserOnList,
      saveUsersList,
      resetUsersList,
      handleAccess,
      deleteAccess,

      // Refs
      form,
    }
  },
})
