import { getInstance } from './index'
import { NavigationGuardNext, Route } from 'vue-router/types/router'

export const authGuard = (to: Route, from: Route, next: NavigationGuardNext) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const authService = getInstance() as any

  const fn = () => {
    if (authService.isAuthenticated) {
      return next()
    }
    to.path !== '/' ? next({ path: '/' }) : next()
  }

  if (!authService.loading) {
    return fn()
  }

  authService.$watch('loading', (loading: boolean) => {
    if (loading === false) {
      return fn()
    }
  })
}

export const homeGuard = (_to: Route, from: Route, next: NavigationGuardNext) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const authService = getInstance() as any
  const fn = () => {
    if (authService.isAuthenticated) {
      return next({ name: 'users' })
    }
    return next()
  }
  if (!authService.loading) {
    return fn()
  }
  authService.$watch('loading', (loading: boolean) => {
    if (loading === false) {
      return fn()
    }
  })
}
