import { defineStore } from 'pinia'
import Api from '@/api'
import { account, facility, department } from '@/types'

const sortAlphabetically = (array: account[] | facility[] | department[]) => {
  array.sort((firstItem, secondItem) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const myKey = firstItem.name ? 'name' : 'value'
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (firstItem[myKey].toLowerCase() < secondItem[myKey].toLowerCase()) return -1
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (firstItem[myKey].toLowerCase() > secondItem[myKey].toLowerCase()) return 1
    return 0
  })
  return array
}

const usePlatformStore = defineStore('platformStore', {
  state: () => ({
    accounts: [] as account[],
    facilities: {} as { [key: string]: facility[] },
    departments: {} as { [key: string]: department[] },
  }),
  actions: {
    async getAccounts(): Promise<void> {
      const accounts = await Api.getAccounts()
      this.accounts = sortAlphabetically(accounts) as account[]
    },
    async getFacilities(accountId: string): Promise<void> {
      if (!this.facilities[accountId]) {
        let facilities = await Api.getFacilities(accountId)
        if (facilities.length > 1) {
          facilities = sortAlphabetically(facilities) as facility[]
        }
        this.facilities = { ...this.facilities, [accountId]: facilities }
      }
    },
    async getDepartments(institutionId: string): Promise<void> {
      if (!this.departments[institutionId]) {
        let departments = await Api.getDepartments(institutionId)
        if (departments.length > 1) {
          departments = sortAlphabetically(departments) as department[]
        }
        this.departments = { ...this.departments, [institutionId]: departments }
      }
    },
  },
})

export default usePlatformStore
