import { defineStore } from 'pinia'
import { User, Role, Department, RequestForm, UserForm } from '@/types'
import Api from '@/api'
import { getInstance } from '../auth'
import { useNotificationStore, useAuthStore } from '../store'

const useUsersStore = defineStore('usersStore', {
  state: () => ({
    users: {} as { [key: string]: User[] },
    accounts: [] as { name: string; accountId: string }[],
    departments: {} as Department,
    loadingUsers: false,
    importedUsers: [] as {
      body: RequestForm
      status: 'pending' | 'success' | 'error'
      message: string
    }[],
    isImportingUsers: false,
    fetchingUsers: false,
    access: {} as { [key: string]: string[] },
    formUsers: [] as UserForm[],
    activePage: 1 as number,
    totalPages: 0 as number,
  }),
  actions: {
    setAccounts(accounts: { name: string; accountId: string }[]) {
      this.accounts = accounts
    },
    setUsers(users: User[], page: number) {
      this.users = {
        ...this.users,
        [page]: [...users],
      }
    },
    setActivePage(page: number) {
      this.activePage = page
    },
    setDepartmentByUserId(userId: string, department: { id: string; name: string }) {
      this.departments[userId] = department || {}
    },
    setAccessByUserId(userId: string, access: string[]) {
      this.access[userId] = access || []
    },
    async requestAccounts() {
      const authService = getInstance()
      const accessToken = await authService.getTokenSilently()
      const gotAccounts = await Api.getAccessAccounts(accessToken)
      this.setAccounts(gotAccounts)
    },
    async requestUsers(
      actor: {
        role: Role
        institutionId: string
        id: string
      },
      search?: string,
      blocked?: boolean,
      accountId?: string,
      roleSearch?: string,
      sort?: {
        firstname?: 'asc' | 'desc'
        email?: 'asc' | 'desc'
        lastname?: 'asc' | 'desc'
        nickname?: 'asc' | 'desc'
        sf_account?: 'asc' | 'desc'
        facility?: 'asc' | 'desc'
      }
    ) {
      this.loadingUsers = true
      const authService = getInstance()
      const accessToken = await authService.getTokenSilently()
      const gotUsers = await Api.getUsers(accessToken, actor.role, actor.institutionId, actor.id, {
        page: this.activePage && this.activePage > 0 ? this.activePage - 1 : 0,
        search,
        blocked,
        accountId,
        roleSearch,
        sort,
      })
      const { users, pages } = gotUsers
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!users && !pages && gotUsers[0]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.setUsers(gotUsers[0].users, this.activePage || 1)
        this.totalPages = 0
        this.loadingUsers = false
        return
      }
      this.setUsers(users, this.activePage || 1)
      this.totalPages = pages
      this.loadingUsers = false
    },
    async deleteUser(user_id: string) {
      const authService = getInstance()
      const accessToken = await authService.getTokenSilently()
      try {
        await Api.deleteAuth0User(accessToken, user_id)
        /* this.users = this.users.filter((user) => user.user_id !== user_id) */
        useNotificationStore().addNotification('User successfully deleted', 'is-success')
        this.requestUsers({
          role: useAuthStore().getRole,
          institutionId: useAuthStore().getFacility,
          id: useAuthStore().getAccountId,
        })
      } catch (error: any) {
        useNotificationStore().addNotification(error.message, 'is-danger')
      }
    },
    async createUsersForm(users: RequestForm[]) {
      const authService = getInstance()
      const accessToken = await authService.getTokenSilently()
      this.formUsers = []
      try {
        for (const user of users) {
          try {
            const result = await Api.createAuth0User(accessToken, user)
            if (result.errors[0]) {
              this.formUsers = [...this.formUsers, user.action.user]
              useNotificationStore().addNotification(
                `User ${user.action.user.email}: ${result.errors[0]}`,
                'is-danger'
              )
              continue
            }
            if (result.warnings.length > 0) {
              result.warnings.forEach((warning: string) => {
                useNotificationStore().addNotification(
                  `User ${user.action.user.email}: ${warning}`,
                  'is-warning'
                )
              })
            }
            useNotificationStore().addNotification(
              `User ${user.action.user.email} successfully created`,
              'is-success'
            )
          } catch (error: any) {
            this.formUsers = [...this.formUsers, user.action.user]
            useNotificationStore().addNotification(
              `User ${user.action.user.email}: ${error.response?.data?.message || error.message}`,
              'is-danger'
            )
          }
        }
      } catch (error: any) {
        useNotificationStore().addNotification(
          error.response?.data?.message || error.message,
          'is-danger'
        )
      } finally {
        this.requestUsers({
          role: useAuthStore().getRole,
          institutionId: useAuthStore().getFacility,
          id: useAuthStore().getAccountId,
        })
      }
    },
    async updateUser(user: RequestForm) {
      const authService = getInstance()
      const accessToken = await authService.getTokenSilently()
      try {
        const result = await Api.updateUser(accessToken, user)
        if (result.errors[0]) {
          useNotificationStore().addNotification(
            `User ${user.action.user.email}: ${result.errors[0]}`,
            'is-danger'
          )
          return
        }
        if (result.warnings.length > 0) {
          result.warnings.forEach((warning: string) => {
            useNotificationStore().addNotification(
              `User ${user.action.user.email}: ${warning}`,
              'is-warning'
            )
          })
        }
        useNotificationStore().addNotification(
          `User ${user.action.user.email} successfully updated`,
          'is-success'
        )
        this.requestUsers({
          role: useAuthStore().getRole,
          institutionId: useAuthStore().getFacility,
          id: useAuthStore().getAccountId,
        })
      } catch (error: any) {
        useNotificationStore().addNotification(
          `User ${user.action.user.email}: ${error.response?.data?.message || error.message}`,
          'is-danger'
        )
      }
    },
    setImportUsers(users: RequestForm[]) {
      this.importedUsers = users.map((body) => ({
        body,
        status: 'pending',
        message: '',
      }))
    },
    async startImportUsers() {
      this.isImportingUsers = true
      this.fetchingUsers = true
      const authService = getInstance()
      const accessToken = await authService.getTokenSilently()
      try {
        for (const { body } of this.importedUsers) {
          try {
            const result = await Api.createAuth0User(accessToken, body)
            this.importedUsers = this.importedUsers.map((importedUser) => {
              if (importedUser.body.action.user.email === body.action.user.email) {
                if (result.errors[0]) {
                  return {
                    ...importedUser,
                    status: 'error',
                    message: result.errors[0],
                  }
                }
                let message = 'User successfully created'
                if (result.warnings.length > 0) {
                  message += ` with warnings: ${result.warnings.join(', ')}`
                }
                return {
                  ...importedUser,
                  status: 'success',
                  message,
                }
              }
              return importedUser
            })
          } catch (error: any) {
            this.importedUsers = this.importedUsers.map((importedUser) => {
              if (importedUser.body.action.user.email === body.action.user.email) {
                return {
                  ...importedUser,
                  status: 'error',
                  message: error.response?.data?.message || error.message,
                }
              }
              return importedUser
            })
          }
        }
        this.fetchingUsers = false
        this.requestUsers({
          role: useAuthStore().getRole,
          institutionId: useAuthStore().getFacility,
          id: useAuthStore().getAccountId,
        })
      } catch (error: any) {
        useNotificationStore().addNotification(
          error.response?.data?.message || error.message,
          'is-danger'
        )
      }
    },
    async requestDepartmentByUserId(user_id: string): Promise<{ name: string; id: string }> {
      if (this.departments[user_id] && this.departments[user_id].id) {
        return this.departments[user_id]
      }
      const authService = getInstance()
      const accessToken = await authService.getTokenSilently()
      const department = await Api.getDepartmentByUserId(accessToken, user_id)
      if (department) {
        this.setDepartmentByUserId(user_id, department)
        return department
      } else {
        return { name: 'No department', id: '' }
      }
    },
    async requestAccessByUserId(user_id: string) {
      if (this.access[user_id]) {
        return this.access[user_id]
      }
      const access = await Api.getAccessByUserId(user_id)
      if (access) {
        this.setAccessByUserId(user_id, access)
        return access
      } else {
        return []
      }
    },
    closeImportUsers() {
      this.importedUsers = []
      this.isImportingUsers = false
    },
  },
  getters: {
    getIsFetchingUsers: (state): boolean => state.fetchingUsers,
    getIsLoadingUsers: (state): boolean => state.loadingUsers,
    getIsImportingUsers: (state): boolean => state.isImportingUsers,
    getUsers: (state: { users: { [key: string]: User[] }; activePage: number }) => {
      const { users, activePage } = state
      if (!users[activePage]) return []
      const usersShow = users[activePage].map((user: User) => {
        return {
          blocked: user.blocked,
          email: user.email,
          firstname: user.firstname,
          lastname: user.lastname,
          nickname: user.nickname,
          role: user.role,
          user_id: user.user_id,
          facility: user.facility?.name || '',
          sf_account: user.sf_account?.name || '',
        }
      })
      return usersShow
    },
    getUserById:
      (state: { users: { [key: string]: User[] } }) =>
      (userId: string): User => {
        const pages = Object.keys(state.users)
        for (const page of pages) {
          const user = state.users[page].find(
            (user: { user_id: string }) => user.user_id === userId
          )
          if (user) {
            return {
              ...user,
              facility: user.facility || { name: '' },
              sf_account: user.sf_account || { name: '' },
            }
          }
        }
        return {} as User
      },
    getDepartmentByUserId:
      (state: { departments: Department }) =>
      (userId: string): { name: string } => {
        return state.departments[userId] || { name: '' }
      },
    getAccounts: (state: { accounts: { name: string; accountId: string }[] }) => {
      state.accounts.sort((a, b) => {
        if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) {
          return -1
        }
        if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) {
          return 1
        }
        return 0
      })
      return state.accounts.map((account) => {
        return {
          id: account.accountId,
          value: account.name,
        }
      })
    },
    getActivePage: (state: { activePage: number }) => state.activePage,
    getPageTotal: (state: { totalPages: number }) => state.totalPages,
  },
})

export default useUsersStore
