import gql from 'graphql-tag'
import apollo from './apolloClient'
import axios from 'axios'
import {
  Role,
  account,
  facility,
  department,
  RequestForm,
  ResponseCreateUser,
  getUsersRequest,
  actorUser,
  readinessReport,
} from '@/types'

const suffixEnv = process.env.VUE_APP_ENV === 'develop' ? '_test' : ''

const sfRequest = async (
  url: string,
  method: string,
  access_token: string,
  body?: unknown,
  query?: unknown
): Promise<{ data?: unknown; error?: string }> => {
  try {
    const response = await axios({
      url: `${process.env.VUE_APP_SF_API}${url}`,
      method,
      data: body,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      params: query,
    })
    return response.data
  } catch (error: any) {
    if (error.response?.status === 429) {
      await new Promise((resolve) => setTimeout(resolve, 2500))
      return sfRequest(url, method, access_token, body)
    }
    return { error: error.response?.data?.message || error.message }
  }
}

const Api = {
  async getUsers(
    access_token: string,
    role: Role,
    institution_id: string,
    id: string,
    {
      page,
      search,
      blocked,
      accountId,
      roleSearch,
      sort,
    }: {
      page?: number
      search?: string
      blocked?: boolean
      accountId?: string
      roleSearch?: string
      sort?: {
        firstname?: 'asc' | 'desc'
        lastname?: 'asc' | 'desc'
        email?: 'asc' | 'desc'
        nickname?: 'asc' | 'desc'
        facility?: 'asc' | 'desc'
        role?: 'asc' | 'desc'
        sf_account?: 'asc' | 'desc'
      }
    }
  ): Promise<getUsersRequest> {
    const response = await sfRequest(
      `/user-manager/users/limit`,
      'POST',
      access_token,
      {
        role,
        institution_id,
        id,
      },
      {
        page,
        limit: 25,
        search,
        blocked,
        accountId,
        role: roleSearch,
        sortFirstName: sort?.firstname,
        sortEmail: sort?.email,
        sortLastName: sort?.lastname,
        sortNickname: sort?.nickname,
        sortAccount: sort?.sf_account,
        sortFacility: sort?.facility,
      }
    )
    if (response.error) {
      return { pages: 0, users: [] }
    }
    return response as getUsersRequest
  },
  async deleteAuth0User(access_token: string, user_id: string) {
    const response = await sfRequest(`/auth0/${user_id}`, 'DELETE', access_token)
    if (response.error) {
      return { error: response.error }
    }
    return response
  },
  async getAccounts(): Promise<account[]> {
    const response = await apollo.query({
      fetchPolicy: 'no-cache',
      query: gql`
        query getAccounts {
          sf_accounts${suffixEnv} {
            account_id
            name
          }
        }
      `,
    })
    return response.data[`sf_accounts${suffixEnv}`]
  },
  async getFacilities(accountId: string): Promise<facility[]> {
    const response = await apollo.query({
      fetchPolicy: 'no-cache',
      query: gql`
        query getFacilities($accountId: String!) {
          facilities${suffixEnv}(where: {account_facilities: {account_id: {_eq: $accountId}}}){
            institution_id
            name
          }
        }
      `,
      variables: {
        accountId,
      },
    })
    return response.data[`facilities${suffixEnv}`]
  },
  async getDepartments(institutionId: string): Promise<department[]> {
    const response = await apollo.query({
      fetchPolicy: 'no-cache',
      query: gql`
        query getDepartments($institutionId: Int!) {
          v2_departments${suffixEnv}(where: {institution_id: {_eq: $institutionId}}){
            id
            v2_department_label {
              name
            }
          }
        }
      `,
      variables: {
        institutionId: parseInt(institutionId),
      },
    })
    return response.data[`v2_departments${suffixEnv}`].map((d: any) => ({
      id: d.id,
      name: d.v2_department_label.name,
    }))
  },
  async getActorUser(sub: string): Promise<actorUser> {
    const response = await apollo.query({
      fetchPolicy: 'no-cache',
      query: gql`
        query getActorUser($sub: String!) {
          users${suffixEnv}(where: {user_id: {_eq: $sub}}) {
            user_id
            account_id
            role
            institution_id
            email
            nickname
          }
        }
      `,
      variables: {
        sub,
      },
    })
    const user = response.data[`users${suffixEnv}`][0]
    return {
      userId: user.user_id,
      accountId: user.account_id,
      role: user.role,
      institutionId: user.institution_id,
      email: user.email,
      nickname: user.nickname,
    }
  },
  async createAuth0User(access_token: string, body: RequestForm): Promise<ResponseCreateUser> {
    const response = await sfRequest(`/user-manager/user`, 'POST', access_token, body)
    if (response.error) {
      return { errors: [response.error], warnings: [] }
    }
    return response as ResponseCreateUser
  },
  async updateUser(access_token: string, body: RequestForm): Promise<ResponseCreateUser> {
    const response = await sfRequest(`/user-manager/edit`, 'PUT', access_token, body)
    if (response.error) {
      throw new Error(response.error)
    }
    return response as ResponseCreateUser
  },
  async getFlatfileToken(user: { id: string; name: string; email: string }, access_token: string) {
    const response = await sfRequest(`/auth0/flatfile`, 'POST', access_token, { user })
    if (response.error) {
      throw new Error(response.error)
    }
    return response as string
  },
  async getDepartmentByUserId(access_token: string, user_id: string) {
    const response = await sfRequest(`/auth0/user/department/${user_id}`, 'GET', access_token)
    if (response.error) {
      return
    }
    return response as { id: string; name: string }
  },
  async getAccessByUserId(user_id: string) {
    const response = await apollo.query({
      fetchPolicy: 'no-cache',
      query: gql`
        query getAccessByUserId($user_id: String!) {
          user_accounts${suffixEnv}(where: {user_id: {_eq: $user_id}}) {
            account_id
          }
        }
      `,
      variables: {
        user_id,
      },
    })
    return response.data[`user_accounts${suffixEnv}`].map((a: any) => a.account_id)
  },
  async getAccountCode(account_id: string) {
    const response = await apollo.query({
      fetchPolicy: 'cache-first',
      query: gql`
        query {
          sf_accounts${suffixEnv}(where: { account_id: { _eq: "${account_id}" } }) {
            code
          }
        }
      `,
    })
    return response.data[`sf_accounts${suffixEnv}`][0].code
  },
  async getAccessAccounts(access_token: string): Promise<{ accountId: string; name: string }[]> {
    const response = await sfRequest(`/user-manager/access/account`, 'POST', access_token, {
      actor: {},
    })
    if (response.error) {
      return []
    }
    return response as { accountId: string; name: string }[]
  },
  async getReadinessReport(access_token: string, mysqlId: string): Promise<readinessReport[]> {
    const response = await sfRequest(`/user-manager/report/${mysqlId}`, 'GET', access_token)
    if (response.error) {
      return []
    }
    return response as readinessReport[]
  },
}

export default Api
