import { defineStore } from 'pinia'

const useNotificationStore = defineStore('notificationStore', {
  state: () => ({
    ref: null as any,
  }),
  actions: {
    setRef(ref: any) {
      this.ref = ref
    },
    addNotification(message: string, type: 'is-success' | 'is-danger' | 'is-info' | 'is-warning') {
      if (
        message === undefined ||
        message === null ||
        message === '' ||
        this.ref === null ||
        message.includes('null')
      )
        return
      this.ref.addAlert(message, type, 5000, true)
    },
  },
})

export default useNotificationStore
