<template>
  <div class="modal-wrapper">
    <div class="container modal">
      <div class="close-modal" @click="closeModal">
        <HSIcon v-if="!canClose" icon="close" />
      </div>
      <div v-if="canClose">
        <h4>We are creating your new users!</h4>
        <p>Please do not close or refresh this browser tab</p>
      </div>
      <div v-else>
        <h4>Saving process complete!</h4>
        <p>You can close or refresh your site now!</p>
      </div>
      <div class="container user-list">
        <div
          class="user"
          v-for="{ body, status, message } in importedUsers"
          :key="body.action.user.email"
        >
          <p>{{ body.action.user.email }}</p>
          <p class="message">{{ message }}</p>
          <HSLoading class="loading" v-if="status === 'pending'" size="20px" />
          <HSIcon v-else :icon="iconName(status)" :color="iconColor(status)" size="26px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useUsersStore } from '../store'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { HSIcon, HSLoading } from '@healthscholars/healthscholars-vue'
export default defineComponent({
  components: {
    HSIcon,
    HSLoading,
  },
  name: 'ImportModalComponent',
  setup() {
    const usersStore = useUsersStore()
    return {
      importedUsers: computed(() => usersStore.importedUsers),
      closeModal: () => usersStore.closeImportUsers(),
      canClose: computed(() => usersStore.getIsFetchingUsers),
    }
  },
  methods: {
    iconName(status: string) {
      switch (status) {
        case 'success':
          return 'check'
        case 'error':
          return 'close'
        default:
          return 'cached'
      }
    },
    iconColor(status: string) {
      switch (status) {
        case 'success':
          return '#4caf50'
        case 'error':
          return '#f44336'
        default:
          return 'black'
      }
    },
  },
})
</script>

<style scoped>
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  max-width: 600px;
  max-height: 800px;
  background-color: white;
  border-radius: 7px;
  padding: 20px;
  overflow-y: auto;
}
.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.user-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: auto;
  overflow-y: auto;
}
.user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 7px;
  background-color: #f5f5f5;
}
.user p {
  margin: 0;
}
.user span {
  border-radius: 50%;
  padding: 4px;
  font-size: 1.1rem;
}
.user .message {
  font-size: 0.8rem;
  color: #505050;
  padding: 0 20px;
}
.loading {
  width: 32px !important;
  height: 32px !important;
}
</style>
