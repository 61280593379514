<template>
  <section class="d-flex flex-column pt-4">
    <router-view
      class="app-view pt-4"
      :class="{ 'container-fluid': $auth.isAuthenticated }"
    ></router-view>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppView',
  components: {},
})
</script>

<style>
@import url('../styles/theme.scss');

.app-view {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  & > * {
    flex-grow: 1;
  }
}
</style>
