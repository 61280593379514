import { computed, reactive, ref } from 'vue'
import { usePanelStore, useUsersStore, useAuthStore, usePlatformStore } from '../store'
import { Role, facility, department } from '../types'

const useForms = () => {
  // ? ------------- Propperties -------------
  const panelStore = usePanelStore()
  const usersStore = useUsersStore()
  const authStore = useAuthStore()
  const platformStore = usePlatformStore()
  const loadings = reactive({ save: false, delete: false })
  const accountsAvailableToAssign = ref()
  const assignedAccounts = ref()

  // ? ------------- Computed -------------
  const accounts = computed(() => usersStore.getAccounts)
  const roles = computed(() => {
    const roles = [
      { id: Role.Admin, value: 'Admin' },
      { id: Role.Learner, value: 'Learner' },
    ]
    if (authStore.getRole === 'Super Admin') {
      roles.push({ id: Role.SuperAdmin, value: 'Super Admin' })
    }
    return roles
  })

  // ? ------------- Methods -------------
  const getFacilities = async (account_id: string) => {
    const result = await platformStore.getFacilities(account_id)
    return result
  }

  const getDepartments = async (institution_id: string) => {
    const result = await platformStore.getDepartments(institution_id)
    return result
  }

  const formatStoreFacilities = (identifier: any) => {
    const facilities = platformStore.facilities
    if (facilities[identifier]) {
      const facilitiesList = facilities[identifier] as facility[]
      if (facilitiesList.length === 0) {
        return [{ id: '', value: 'No facilities found' }]
      }
      return facilitiesList.map((facility: facility) => ({
        id: facility.institution_id.toString(),
        value: facility.name,
      }))
    }
  }

  const formatStoreDepartments = (identifier: any) => {
    const departments = platformStore.departments
    if (departments[identifier]) {
      const departmentsList = departments[identifier] as department[]
      if (departmentsList.length === 0) {
        return [{ id: '', value: 'No departments found' }]
      }
      return departmentsList.map((department: department) => ({
        id: department.id.toString(),
        value: department.name,
      }))
    }
    return []
  }

  const deleteUser = async (userId: any) => {
    if (loadings.delete || loadings.save) return
    loadings.delete = true
    await usersStore.deleteUser(userId)
    panelStore.isEditPanelOpen = false
    loadings.delete = false
  }

  const validateForm = (form: any) => {
    if (form.value) {
      let htmlValidator = true
      htmlValidator = form.value.validate()
      return htmlValidator
    }
    return false
  }

  const prepareAccess = (userAccounts: string[], isAssignAccounts: boolean) => {
    if (!isAssignAccounts) return
    const accountsById = accounts.value.map((account: { id: string }) => account.id)
    const accountsIdsFiltered = accountsById.filter(
      (accountId: string) => !userAccounts.includes(accountId)
    )
    accountsAvailableToAssign.value = accounts.value.filter((account) =>
      accountsIdsFiltered.includes(account.id)
    )
    assignedAccounts.value = accounts.value.filter(
      (account) => !accountsIdsFiltered.includes(account.id)
    )
  }

  return {
    // Properties
    loadings,
    accountsAvailableToAssign,
    assignedAccounts,

    // Getters
    roles,
    accounts,
    currentUserRole: computed(() => authStore.getRole),

    // Methods
    formatStoreFacilities,
    formatStoreDepartments,
    validateForm,
    deleteUser,
    getFacilities,
    getDepartments,
    prepareAccess,
  }
}

export default useForms
