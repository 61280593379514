<template>
  <div id="app" class="app-container">
    <HSStyling />
    <HSNavbar
      :loggedIn="$auth.isAuthenticated"
      :needsBrandIcon="false"
      :background="null"
      class="hsNavbar theme--light"
    >
      <a href="/" class="logo py-2">
        <svg
          fill="var(--color-brand-gray)"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 400 64.94"
        >
          <defs></defs>
          <g id="Logos">
            <g>
              <g>
                <g class="logo-text">
                  <path
                    class="cls-1"
                    d="M88.2,24.64h3v6.7h7.82v-6.7h3v15.68h-3v-6.36h-7.82v6.36h-3v-15.68Z"
                  />
                  <path
                    class="cls-1"
                    d="M112.61,24.64h11.49v2.62h-8.49v3.87h7.62v2.62h-7.62v3.94h8.76v2.62h-11.76v-15.68Z"
                  />
                  <path
                    class="cls-1"
                    d="M145.74,40.32l-1.39-3.31h-7.66l-1.39,3.31h-3.11l6.88-15.68h3.11l6.79,15.68h-3.23Zm-7.97-5.89h5.51l-2.73-6.59-2.78,6.59Z"
                  />
                  <path class="cls-1" d="M157.58,24.64h3v12.97h6.76v2.71h-9.77v-15.68Z" />
                  <path
                    class="cls-1"
                    d="M174.67,24.64h12.79v2.67h-4.88v13.01h-3.02v-13.01h-4.88v-2.67Z"
                  />
                  <path
                    class="cls-1"
                    d="M196.31,24.64h3v6.7h7.82v-6.7h3v15.68h-3v-6.36h-7.82v6.36h-3v-15.68Z"
                  />
                  <path
                    class="cls-1"
                    d="M238.5,27.53c-.84-.27-1.59-.4-2.23-.4-.72,0-1.28,.13-1.7,.4-.42,.27-.63,.64-.63,1.12,0,.43,.15,.8,.44,1.09,.29,.29,.66,.53,1.1,.72,.44,.19,1.04,.39,1.8,.62,1.06,.31,1.93,.63,2.61,.94,.68,.31,1.26,.78,1.74,1.39,.48,.61,.72,1.42,.72,2.42s-.26,1.79-.77,2.5c-.52,.71-1.22,1.25-2.13,1.62-.9,.37-1.94,.56-3.1,.56-1.22,0-2.42-.23-3.58-.68-1.16-.46-2.18-1.06-3.05-1.82l1.25-2.55c.79,.73,1.68,1.31,2.67,1.72,.99,.42,1.9,.63,2.76,.63s1.49-.16,1.97-.48c.48-.32,.72-.76,.72-1.31,0-.45-.15-.82-.44-1.11-.29-.29-.66-.53-1.11-.71-.45-.18-1.06-.38-1.84-.6-1.06-.31-1.93-.62-2.6-.92-.67-.3-1.25-.75-1.72-1.35-.48-.6-.72-1.4-.72-2.38,0-.91,.24-1.71,.73-2.38,.48-.68,1.17-1.21,2.06-1.58s1.92-.56,3.08-.56c1.02,0,2.02,.15,3.01,.45,.99,.3,1.88,.7,2.65,1.21l-1.21,2.62c-.81-.49-1.63-.87-2.47-1.14Z"
                  />
                  <path
                    class="cls-1"
                    d="M261.3,27.67c-.75-.36-1.52-.54-2.3-.54-.97,0-1.86,.23-2.67,.69-.81,.46-1.44,1.1-1.9,1.92-.46,.81-.69,1.71-.69,2.68s.23,1.86,.69,2.68c.46,.81,1.1,1.46,1.9,1.93,.81,.47,1.69,.71,2.67,.71,.75,0,1.5-.17,2.25-.49,.75-.33,1.42-.78,2-1.37l1.77,1.97c-.82,.84-1.78,1.5-2.87,1.98-1.09,.49-2.2,.73-3.31,.73-1.51,0-2.89-.36-4.14-1.07-1.25-.72-2.24-1.69-2.96-2.92-.72-1.23-1.08-2.59-1.08-4.09s.37-2.85,1.1-4.08c.73-1.22,1.73-2.19,2.99-2.89,1.26-.7,2.66-1.05,4.2-1.05,1.12,0,2.22,.22,3.29,.67,1.08,.45,1.99,1.07,2.76,1.86l-1.75,2.2c-.55-.64-1.21-1.14-1.96-1.5Z"
                  />
                  <path
                    class="cls-1"
                    d="M274.22,24.64h3v6.7h7.82v-6.7h3v15.68h-3v-6.36h-7.82v6.36h-3v-15.68Z"
                  />
                  <path
                    class="cls-1"
                    d="M310,25.49c1.28,.7,2.28,1.66,3.01,2.89,.73,1.22,1.1,2.58,1.1,4.08s-.37,2.86-1.1,4.09c-.73,1.23-1.74,2.21-3.01,2.92-1.28,.72-2.69,1.07-4.24,1.07s-2.97-.36-4.24-1.07c-1.28-.72-2.28-1.69-3.01-2.92-.73-1.23-1.1-2.59-1.1-4.09s.37-2.85,1.1-4.08c.73-1.22,1.74-2.19,3.01-2.89,1.28-.7,2.69-1.05,4.24-1.05s2.97,.35,4.24,1.05Zm-6.86,2.34c-.82,.47-1.47,1.11-1.95,1.93-.48,.81-.72,1.71-.72,2.7s.24,1.89,.73,2.71c.49,.82,1.13,1.47,1.95,1.95,.81,.48,1.7,.72,2.65,.72s1.81-.24,2.62-.72c.81-.48,1.44-1.13,1.92-1.95,.47-.82,.71-1.73,.71-2.71s-.24-1.88-.71-2.7c-.47-.81-1.11-1.45-1.92-1.93-.81-.47-1.68-.7-2.62-.7s-1.84,.23-2.67,.7Z"
                  />
                  <path class="cls-1" d="M323.47,24.64h3v12.97h6.76v2.71h-9.77v-15.68Z" />
                  <path
                    class="cls-1"
                    d="M354.02,40.32l-1.39-3.31h-7.66l-1.39,3.31h-3.11l6.88-15.68h3.11l6.79,15.68h-3.23Zm-7.97-5.89h5.51l-2.73-6.59-2.78,6.59Z"
                  />
                  <path
                    class="cls-1"
                    d="M375.68,40.32l-2.71-4.64c-.12,.01-.31,.02-.56,.02h-3.54v4.61h-3v-15.68h6.54c2.03,0,3.6,.47,4.7,1.41,1.1,.94,1.66,2.27,1.66,3.99,0,1.22-.26,2.27-.8,3.14-.53,.87-1.3,1.52-2.32,1.95l3.45,5.2h-3.43Zm-6.81-7.26h3.54c1.13,0,2-.25,2.6-.74,.6-.49,.9-1.22,.9-2.2s-.3-1.67-.9-2.15-1.46-.72-2.6-.72h-3.54v5.8Z"
                  />
                  <path
                    class="cls-1"
                    d="M396.16,27.53c-.84-.27-1.59-.4-2.23-.4-.72,0-1.28,.13-1.7,.4-.42,.27-.63,.64-.63,1.12,0,.43,.15,.8,.44,1.09,.29,.29,.66,.53,1.1,.72,.44,.19,1.04,.39,1.8,.62,1.06,.31,1.93,.63,2.61,.94,.68,.31,1.26,.78,1.74,1.39,.48,.61,.72,1.42,.72,2.42s-.26,1.79-.77,2.5c-.52,.71-1.22,1.25-2.13,1.62-.9,.37-1.94,.56-3.1,.56-1.22,0-2.42-.23-3.58-.68-1.16-.46-2.18-1.06-3.05-1.82l1.25-2.55c.79,.73,1.68,1.31,2.67,1.72,.99,.42,1.9,.63,2.76,.63s1.49-.16,1.97-.48,.72-.76,.72-1.31c0-.45-.15-.82-.44-1.11-.29-.29-.66-.53-1.11-.71-.45-.18-1.06-.38-1.84-.6-1.06-.31-1.93-.62-2.6-.92-.67-.3-1.25-.75-1.72-1.35-.48-.6-.72-1.4-.72-2.38,0-.91,.24-1.71,.73-2.38,.48-.68,1.17-1.21,2.06-1.58,.89-.37,1.92-.56,3.08-.56,1.02,0,2.02,.15,3.01,.45,.99,.3,1.88,.7,2.65,1.21l-1.21,2.62c-.81-.49-1.63-.87-2.47-1.14Z"
                  />
                </g>
                <g>
                  <path
                    class="cls-1"
                    d="M32.49,19.52c1.58,0,2.86-1.28,2.86-2.86s-2.86-5.09-2.86-5.09c0,0-2.86,3.51-2.86,5.09s1.28,2.86,2.86,2.86Z"
                  />
                  <path
                    class="cls-1"
                    d="M32.49,24.61c1.58,0,2.86,1.28,2.86,2.86s-2.86,5.09-2.86,5.09c0,0-2.86-3.51-2.86-5.09s1.28-2.86,2.86-2.86Z"
                  />
                  <path
                    class="cls-1"
                    d="M30.61,22.08c0-1.58-1.28-2.86-2.86-2.86-1.58,0-5.09,2.86-5.09,2.86,0,0,3.51,2.86,5.09,2.86,1.58,0,2.86-1.28,2.86-2.86Z"
                  />
                  <path
                    class="cls-1"
                    d="M34.37,22.08c0-1.58,1.28-2.86,2.86-2.86s5.09,2.86,5.09,2.86c0,0-3.51,2.86-5.09,2.86s-2.86-1.28-2.86-2.86Z"
                  />
                  <path
                    class="cls-1"
                    d="M27.62,17.28c1.12-1.12,1.12-2.93,0-4.05-1.12-1.12-5.62-1.57-5.62-1.57,0,0,.46,4.5,1.57,5.62s2.93,1.12,4.05,0Z"
                  />
                  <path
                    class="cls-1"
                    d="M21.33,20.54c1.12-1.12,1.12-2.93,0-4.05s-5.62-1.57-5.62-1.57c0,0,.46,4.5,1.57,5.62,1.12,1.12,2.93,1.12,4.05,0Z"
                  />
                  <path
                    class="cls-1"
                    d="M21.33,23.61c1.12,1.12,1.12,2.93,0,4.05s-5.62,1.57-5.62,1.57c0,0,.46-4.5,1.57-5.62,1.12-1.12,2.93-1.12,4.05,0Z"
                  />
                  <path
                    class="cls-1"
                    d="M27.62,26.84c-1.12-1.12-2.93-1.12-4.05,0s-1.57,5.62-1.57,5.62c0,0,4.5-.46,5.62-1.57,1.12-1.12,1.12-2.93,0-4.05Z"
                  />
                  <path
                    class="cls-1"
                    d="M37.32,17.28c-1.12-1.12-1.12-2.93,0-4.05,1.12-1.12,5.62-1.57,5.62-1.57,0,0-.46,4.5-1.57,5.62s-2.93,1.12-4.05,0Z"
                  />
                  <path
                    class="cls-1"
                    d="M43.62,20.54c-1.12-1.12-1.12-2.93,0-4.05s5.62-1.57,5.62-1.57c0,0-.46,4.5-1.57,5.62-1.12,1.12-2.93,1.12-4.05,0Z"
                  />
                  <path
                    class="cls-1"
                    d="M43.62,23.61c-1.12,1.12-1.12,2.93,0,4.05,1.12,1.12,5.62,1.57,5.62,1.57,0,0-.46-4.5-1.57-5.62s-2.93-1.12-4.05,0Z"
                  />
                  <path
                    class="cls-1"
                    d="M37.32,26.84c1.12-1.12,2.93-1.12,4.05,0s1.57,5.62,1.57,5.62c0,0-4.5-.46-5.62-1.57-1.12-1.12-1.12-2.93,0-4.05Z"
                  />
                  <path
                    class="cls-1"
                    d="M38.23,33.65l-2.54-1.12c-1.43,1.63-2.49,3.46-3.19,5.41-.7-1.95-1.77-3.79-3.2-5.42l-2.54,1.12c2.6,2.63,3.96,5.88,4.1,9.16-.06,3.38-1.42,6.74-4.1,9.45l2.54,1.12c1.43-1.63,2.5-3.47,3.2-5.42,.7,1.94,1.76,3.78,3.19,5.41l2.54-1.12c-2.67-2.7-4.03-6.06-4.09-9.44,.14-3.28,1.49-6.52,4.09-9.14Z"
                  />
                </g>
              </g>
              <path
                class="cls-1 logo-icon"
                d="M32.47,0C14.54,0,0,14.54,0,32.47s14.54,32.47,32.47,32.47,32.47-14.54,32.48-32.47c-.01-17.93-14.54-32.47-32.48-32.47Zm21.75,54.21c-5.57,5.57-13.25,9.01-21.75,9.01s-16.17-3.44-21.74-9.01C5.16,48.65,1.72,40.96,1.72,32.47S5.16,16.29,10.73,10.73C16.3,5.16,23.98,1.72,32.47,1.72s16.18,3.44,21.75,9.01c5.56,5.56,9,13.25,9,21.74s-3.44,16.18-9,21.74Z"
              />
            </g>
          </g>
        </svg>
      </a>
      <a
        v-if="isSuperAdmin"
        :href="customerSuccessManagerURI"
        class="hs-button hs-primary-button is-outlined fs-sm app-link"
        >Customer Success</a
      >
      <template #actions>
        <a
          v-if="isSuperAdmin || isAdmin"
          href="https://healthscholars.notion.site/User-Manager-Documentation-dfd9a2acfe724a22b7be8c3233680cf3"
          class="icon-link"
          target="_blank"
        >
          <HSIcon icon="helpoutline" />
        </a>

        <div class="dropdown d-flex flex-column ml-2">
          <a href="#0" class="user-image" @click="showInfo"><img :src="user.picture" alt="" /></a>
          <div class="dropdown-menu" :class="{ 'is-visible': showUserInfo }">
            <div class="user-name-label" :title="user.email" data-test="navbar-user-name">
              {{ user.email }}
            </div>
            <div class="user-role-label mb-2" data-test="navbar-user-role">
              {{ getRole }}
            </div>
            <div v-if="accountCode && !isLearner" class="mr-2" data-test="account-code-container">
              <small class="">Account code: &nbsp;</small>
              <SelectComponent
                :isDisabled="isAdmin && getAccounts.length > 0"
                dataTest="account-code-select"
                class="w-100 mb-0"
                name="accountCode"
                :posibleItems="getAccounts"
                @onChange="(account) => setAccountCode(account)"
                :valueHandler="getAccounts[0]?.id || ''"
              />
              <div class="account-code-identifier" data-test="account-code">
                <strong>{{ accountCode }}</strong>
              </div>
            </div>
            <HSButton
              v-if="accountCode"
              data-test="logout-button"
              active
              dismissible
              class="hs-primary-button fs-sm"
              :label="loginButtonLabel"
              @onClick="setLogin"
            />
          </div>
        </div>
        <HSButton
          v-if="$route.name == 'landing'"
          data-test="login-button"
          active
          dismissible
          class="hs-primary-butto"
          variant="is-outline"
          :label="loginButtonLabel"
          @onClick="setLogin"
        />
      </template>
    </HSNavbar>
    <HSAlertStack ref="alert" class="stack" />
    <SidebarEditForm />
    <SidebarCreateForm />
    <main id="main" :class="{ 'mb-auto': !this.$auth.isAuthenticated }" class="main">
      <router-view />
    </main>
    <ImportModal v-if="isImporting" />
  </div>
</template>

<script lang="ts">
import {
  HSStyling,
  HSNavbar,
  HSIcon,
  HSButton,
  HSAlertStack,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from '@healthscholars/healthscholars-vue'
import { defineComponent, computed } from 'vue'
import { useAuthStore, useUsersStore, useNotificationStore } from './store'
import { AuthUser } from './types'
import ImportModal from './components/ImportModalComponent.vue'
import SelectComponent from './components/SelectComponent.vue'
import SidebarEditForm from './components/SidebarEditForm/SidebarEditForm.vue'
import SidebarCreateForm from './components/SidebarCreateForm/SidebarCreateForm.vue'

export default defineComponent({
  name: 'App',
  components: {
    HSNavbar,
    HSStyling,
    HSIcon,
    HSAlertStack,
    ImportModal,
    HSButton,
    SelectComponent,
    SidebarEditForm,
    SidebarCreateForm,
  },
  computed: {
    loginButtonLabel(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.$auth.isAuthenticated ? 'Logout' : 'Login'
    },
    appList() {
      const apps = []
      const userManagerURI = process.env.VUE_APP_LANDING_PAGE_URI
      const customerSuccessManagerURI = process.env.VUE_APP_CUSTOMER_SUCCESS_PAGE_URI

      apps.push(userManagerURI, customerSuccessManagerURI)

      return apps
    },
  },
  data() {
    return {
      landingPageUri: process.env.VUE_APP_LANDING_PAGE_URI,
      customerSuccessManagerURI: process.env.VUE_APP_CUSTOMER_SUCCESS_PAGE_URI,
      showUserInfo: false,
      selected: '',
    }
  },
  methods: {
    setLogin() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!this.$auth.isAuthenticated) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.$auth.loginWithRedirect()
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.$auth.logout({
          returnTo: window.location.origin,
        })
      }
    },
    showInfo() {
      this.showUserInfo = !this.showUserInfo
    },
  },
  setup() {
    const authStore = useAuthStore()
    const usersStore = useUsersStore()
    const notificationStore = useNotificationStore()

    return {
      accountCode: computed(() => authStore.getAccountCode),
      getRole: computed(() => authStore.getRole),
      isSuperAdmin: computed(() => authStore.isSuperAdmin),
      isAdmin: computed(() => authStore.isAdmin),
      isLearner: computed(() => authStore.isLearner),
      setAccountCode: (id: string) => authStore.setActiveAccountCode(id),
      setUser: authStore.setUser,
      user: computed(() => authStore.user),
      getAccounts: computed(() => usersStore.getAccounts),
      isImporting: computed(() => usersStore.getIsImportingUsers),
      setRef: computed(() => notificationStore.setRef),
    }
  },
  mounted() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$auth.$watch('user', (user: AuthUser) => {
      if (user) {
        this.setUser(user)
      }
    })
    this.setRef(this.$refs.alert)
  },
})
</script>

<style>
.app-container,
.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.hs-navbar[data-v-366f17ce].hs-navbar[data-v-366f17ce] {
  position: sticky;
  top: 0;
}

.hs-navbar--menu {
  align-items: center;
  padding-right: 0;
  padding-left: 0;
}

.hs-navbar--brand {
  display: none;
}

.hs-button.hs-button {
  align-self: center;
  border-radius: var(--border-radius) !important;
  display: block;
  color: var(--color-light);
  font-weight: 600;
  font-size: 0.9rem;
  min-width: 50px;
  padding-top: var(--size-1);
  padding-right: var(--size-2);
  padding-bottom: var(--size-1);
  padding-left: var(--size-2);
  word-wrap: break-word;

  &:hover,
  &:focus {
    background: var(--color-primary);
    color: var(--color-light);
    text-decoration: none;
  }

  &.is-light {
    background: var(--color-gray-4);
    color: currentColor;

    &:hover,
    &:focus {
      background: var(--color-primary);
      color: var(--color-light);
    }
  }

  &.is-success {
    background: var(--color-success);
    color: var(--color-light);

    &:hover,
    &:focus {
      background: var(--color-success-dark);
      color: var(--color-light);
    }
  }

  &.is-outlined {
    background: var(--color-primary-alpha);
    border: 1px solid var(--color-primary);
    color: var(--color-primary);

    &:hover,
    &:focus {
      background: var(--color-primary) !important;
      color: var(--color-light) !important;
    }
  }
}

.button-small.button-small,
.hs-confirm-buttons--button-trigger.hs-confirm-buttons--button-trigger {
  min-width: auto;

  .hs-button__content .icon {
    margin: 0;
  }
}

.hs-primary-button.hs-primary-button {
  align-self: center;
  background: var(--color-primary);
  border: 1px solid var(--color-primary);
  border-radius: var(--border-radius-pill) !important;
  font-weight: 400;
  min-width: 76px;
  padding-top: var(--size-2);
  padding-right: var(--size-3);
  padding-bottom: var(--size-2);
  padding-left: var(--size-3);
  text-transform: uppercase;

  &:hover,
  &:focus {
    background: var(--color-primary-alpha);
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
  }

  &.is-outlined {
    background: var(--color-primary-alpha);
    border: 1px solid var(--color-primary);
    color: var(--color-primary);

    &:hover,
    &:focus {
      background: var(--color-primary) !important;
      color: var(--color-light) !important;
    }
  }
}

button.icon-button.icon-button {
  background: transparent;
  border: none;
  color: var(--color-gray-6) !important;
  display: flex;
  align-items: center;
  padding: 0 var(--size-1);
}

button.icon-button.icon-button:hover {
  background: transparent;
  border: none;
}

button.icon-button.icon-button:hover .icon {
  color: var(--color-gray-9) !important;
}

button.icon-button.icon-button .icon {
  font-size: 32px;
  color: var(--color-gray-6) !important;
  max-width: 32px;
}

.logo > svg {
  max-width: 250px;
  width: 100%;
}

.logo-icon {
  min-width: 50px;
}

p > a {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

.hs-navbar--brand {
  display: none !important;
}

.stack {
  z-index: 100;
}

.account-code-container {
  align-items: center;
  border-bottom: 1px solid var(--color-gray-4);
  display: flex;
  font-size: 0.875rem;
  margin-bottom: var(--size-3);
  padding-bottom: var(--size-3);
}

.dropdown {
  --dropdown-height: 200px;
  --dropdown-width: 450px;
  --dropdown-width-min: 250px;
  position: relative;
}

.dropdown-menu {
  background-color: var(--color-gray-2);
  border-radius: 8px;
  box-shadow: 4px 4px 10px var(--color-gray-4);
  font-size: 0.875rem;
  max-height: var(--dropdown-height);
  max-width: var(--dropdown-width);
  min-width: var(--dropdown-width-min);
  top: 4px;
  right: 3rem;
  opacity: 0;
  position: absolute;
  padding-top: var(--size-2);
  padding-right: var(--size-3);
  padding-bottom: var(--size-3);
  padding-left: var(--size-3);
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}

.user-image {
  border-radius: 50px;
  height: 40px;
  margin-right: 0;
  overflow: hidden;
  width: 40px;
}

.dropdown-menu.is-visible {
  opacity: 1;
  pointer-events: auto;
}

.hs-navbar--menu__end.hs-navbar--menu__end {
  align-self: center;
}

.icon-link {
  height: 32px;
  width: 32px;
  margin: auto;
  overflow: hidden;
  & > .icon {
    color: var(--color-gray-6);
    font-size: 32px;
  }
}

.user-name-label {
  font-weight: 700;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hs-navbar--menu__start.hs-navbar--menu__start {
  flex-grow: 1;
  justify-content: space-between !important;
  /* margin-right: var(--size-3); */
}

.app-link {
  margin-right: auto;
  margin-left: auto;
  position: relative;

  @media (min-width: 700px) {
    left: -40px;
  }

  @media (min-width: 1700px) {
    left: -82px;
  }
}

@media (max-width: 700px) {
  .logo-text {
    display: none;
  }

  .logo > svg {
    max-width: 100%;
  }
}

.hs-data-table {
  overflow-x: auto !important;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.account-code-identifier {
  font-size: 0.975rem;
  margin: 3px auto;
}
</style>
